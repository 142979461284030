// @generated by protoc-gen-connect-es v1.3.0 with parameter "target=js+dts"
// @generated from file services/dresskare_back/vendor.proto (package dresskareback.vendor, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AnnouncementDestroyRequest, AnnouncementListRequest, AnnouncementListResponse, AnnouncementPartialUpdateRequest, AnnouncementRequest, AnnouncementResponse, AnnouncementRetrieveRequest, CompanyDestroyRequest, CompanyListRequest, CompanyListResponse, CompanyPartialUpdateRequest, CompanyRequest, CompanyResponse, CompanyRetrieveRequest, CustomerAcceptLegalTermRequest, CustomerAcceptLegalTermResponse, CustomerCheckIfCustomerEmailExistRequest, CustomerCheckIfCustomerEmailExistResponse, CustomerGetUuidByEmailRequest, CustomerGetUuidByEmailResponse, CustomerImportDataResponse, CustomerLessPermissionRequest, CustomerListRequest, CustomerListResponse, CustomerRequest, CustomerResponse, CustomerRetrieveByKeycloakUuidRequest, CustomerRetrieveRequest, CustomerSetSentSetailsRequest, CustomerSetSentSetailsResponse, ImportCustomerRequest, RateSlideDestroyRequest, RateSlideListRequest, RateSlideListResponse, RateSlidePartialUpdateRequest, RateSlideRequest, RateSlideResponse, RateSlideRetrieveRequest, VendorCreateRequest, VendorCreateResponse, VendorGetLastActivityRequest, VendorLastActivityResponse, VendorListRequest, VendorListResponse, VendorPartialUpdateRequest, VendorRequest, VendorResponse, VendorRetrieveByKeycloakUuidRequest, VendorRetrieveRequest } from "./vendor_pb.js";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service dresskareback.vendor.AnnouncementController
 */
export const AnnouncementController = {
  typeName: "dresskareback.vendor.AnnouncementController",
  methods: {
    /**
     * @generated from rpc dresskareback.vendor.AnnouncementController.Create
     */
    create: {
      name: "Create",
      I: AnnouncementRequest,
      O: AnnouncementResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.vendor.AnnouncementController.Destroy
     */
    destroy: {
      name: "Destroy",
      I: AnnouncementDestroyRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.vendor.AnnouncementController.List
     */
    list: {
      name: "List",
      I: AnnouncementListRequest,
      O: AnnouncementListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.vendor.AnnouncementController.PartialUpdate
     */
    partialUpdate: {
      name: "PartialUpdate",
      I: AnnouncementPartialUpdateRequest,
      O: AnnouncementResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.vendor.AnnouncementController.Retrieve
     */
    retrieve: {
      name: "Retrieve",
      I: AnnouncementRetrieveRequest,
      O: AnnouncementResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.vendor.AnnouncementController.Update
     */
    update: {
      name: "Update",
      I: AnnouncementRequest,
      O: AnnouncementResponse,
      kind: MethodKind.Unary,
    },
  }
};

/**
 * @generated from service dresskareback.vendor.CompanyController
 */
export const CompanyController = {
  typeName: "dresskareback.vendor.CompanyController",
  methods: {
    /**
     * @generated from rpc dresskareback.vendor.CompanyController.Create
     */
    create: {
      name: "Create",
      I: CompanyRequest,
      O: CompanyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.vendor.CompanyController.Destroy
     */
    destroy: {
      name: "Destroy",
      I: CompanyDestroyRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.vendor.CompanyController.List
     */
    list: {
      name: "List",
      I: CompanyListRequest,
      O: CompanyListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.vendor.CompanyController.PartialUpdate
     */
    partialUpdate: {
      name: "PartialUpdate",
      I: CompanyPartialUpdateRequest,
      O: CompanyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.vendor.CompanyController.Retrieve
     */
    retrieve: {
      name: "Retrieve",
      I: CompanyRetrieveRequest,
      O: CompanyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.vendor.CompanyController.Update
     */
    update: {
      name: "Update",
      I: CompanyRequest,
      O: CompanyResponse,
      kind: MethodKind.Unary,
    },
  }
};

/**
 * @generated from service dresskareback.vendor.CustomerController
 */
export const CustomerController = {
  typeName: "dresskareback.vendor.CustomerController",
  methods: {
    /**
     * @generated from rpc dresskareback.vendor.CustomerController.AcceptLegalTerm
     */
    acceptLegalTerm: {
      name: "AcceptLegalTerm",
      I: CustomerAcceptLegalTermRequest,
      O: CustomerAcceptLegalTermResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.vendor.CustomerController.CheckIfCustomerEmailExist
     */
    checkIfCustomerEmailExist: {
      name: "CheckIfCustomerEmailExist",
      I: CustomerCheckIfCustomerEmailExistRequest,
      O: CustomerCheckIfCustomerEmailExistResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.vendor.CustomerController.Create
     */
    create: {
      name: "Create",
      I: CustomerRequest,
      O: CustomerResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.vendor.CustomerController.GetUuidByEmail
     */
    getUuidByEmail: {
      name: "GetUuidByEmail",
      I: CustomerGetUuidByEmailRequest,
      O: CustomerGetUuidByEmailResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.vendor.CustomerController.ImportData
     */
    importData: {
      name: "ImportData",
      I: ImportCustomerRequest,
      O: CustomerImportDataResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.vendor.CustomerController.List
     */
    list: {
      name: "List",
      I: CustomerListRequest,
      O: CustomerListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.vendor.CustomerController.Retrieve
     */
    retrieve: {
      name: "Retrieve",
      I: CustomerRetrieveRequest,
      O: CustomerResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.vendor.CustomerController.RetrieveByKeycloakUuid
     */
    retrieveByKeycloakUuid: {
      name: "RetrieveByKeycloakUuid",
      I: CustomerRetrieveByKeycloakUuidRequest,
      O: CustomerResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.vendor.CustomerController.SetSentSetails
     */
    setSentSetails: {
      name: "SetSentSetails",
      I: CustomerSetSentSetailsRequest,
      O: CustomerSetSentSetailsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.vendor.CustomerController.Update
     */
    update: {
      name: "Update",
      I: CustomerRequest,
      O: CustomerResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.vendor.CustomerController.UpdateLessPermission
     */
    updateLessPermission: {
      name: "UpdateLessPermission",
      I: CustomerLessPermissionRequest,
      O: CustomerResponse,
      kind: MethodKind.Unary,
    },
  }
};

/**
 * @generated from service dresskareback.vendor.RateSlideController
 */
export const RateSlideController = {
  typeName: "dresskareback.vendor.RateSlideController",
  methods: {
    /**
     * @generated from rpc dresskareback.vendor.RateSlideController.Create
     */
    create: {
      name: "Create",
      I: RateSlideRequest,
      O: RateSlideResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.vendor.RateSlideController.Destroy
     */
    destroy: {
      name: "Destroy",
      I: RateSlideDestroyRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.vendor.RateSlideController.List
     */
    list: {
      name: "List",
      I: RateSlideListRequest,
      O: RateSlideListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.vendor.RateSlideController.PartialUpdate
     */
    partialUpdate: {
      name: "PartialUpdate",
      I: RateSlidePartialUpdateRequest,
      O: RateSlideResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.vendor.RateSlideController.Retrieve
     */
    retrieve: {
      name: "Retrieve",
      I: RateSlideRetrieveRequest,
      O: RateSlideResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.vendor.RateSlideController.Update
     */
    update: {
      name: "Update",
      I: RateSlideRequest,
      O: RateSlideResponse,
      kind: MethodKind.Unary,
    },
  }
};

/**
 * @generated from service dresskareback.vendor.VendorController
 */
export const VendorController = {
  typeName: "dresskareback.vendor.VendorController",
  methods: {
    /**
     * @generated from rpc dresskareback.vendor.VendorController.Create
     */
    create: {
      name: "Create",
      I: VendorCreateRequest,
      O: VendorCreateResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.vendor.VendorController.GetLastActivity
     */
    getLastActivity: {
      name: "GetLastActivity",
      I: VendorGetLastActivityRequest,
      O: VendorLastActivityResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.vendor.VendorController.List
     */
    list: {
      name: "List",
      I: VendorListRequest,
      O: VendorListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.vendor.VendorController.PartialUpdate
     */
    partialUpdate: {
      name: "PartialUpdate",
      I: VendorPartialUpdateRequest,
      O: VendorResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.vendor.VendorController.Retrieve
     */
    retrieve: {
      name: "Retrieve",
      I: VendorRetrieveRequest,
      O: VendorResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.vendor.VendorController.RetrieveByKeycloakUuid
     */
    retrieveByKeycloakUuid: {
      name: "RetrieveByKeycloakUuid",
      I: VendorRetrieveByKeycloakUuidRequest,
      O: VendorResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.vendor.VendorController.Update
     */
    update: {
      name: "Update",
      I: VendorRequest,
      O: VendorResponse,
      kind: MethodKind.Unary,
    },
  }
};

